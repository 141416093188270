import React from "react"
import Button from "../form/Button"
import { Link } from "react-router-dom"

function SocailManagement({ full }) {
    if (!full) {
        return (
            <div>
                <h1>Brand-focused digital marketing delivers exceptional results</h1>
                <p className="my-12 leading-9 font-light">
                    Transform your online presence with our dynamic digital marketing services. 
                    From social media and email campaigns to influencer partnerships, we craft targeted 
                    strategies that engage your audience and elevate your brand’s voice.
                </p>
                <Link to={"mailto:?to=info@nextedgestudio.com&subject=Social Management&body=Hello, "}>
                    <Button dark css="w-full">Let's Talk Social Management</Button>
                </Link>
            </div>
        )
    }

    return (
        <div></div>
    )
}

export default SocailManagement
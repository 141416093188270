import React from "react"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Carousel(props) {
    const imgSettings = {
        dots: true,
        infinite: true,
        speed: 1500, // Adjust the transition speed
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Time each slide is shown
        fade: true, // Enables fade transition
        cssEase: 'ease-in-out', // Smooth transition
        arrows: true, // Show previous/next arrows
    };

    const reviewSettings = {
        ...imgSettings,
        autoplay: false,
        autoplaySpeed: 5000, // Time each slide is shown
    };

    const images = [
        { src: 'img/carousel/carousel-1.jpg' },
        { src: 'img/carousel/carousel-2.jpg' },
        { src: 'img/carousel/carousel-3.jpg' },
        { src: 'img/carousel/carousel-4.jpg' }
    ]

    const reviews = [
        {
            title: 'I found their process impressive…They delivered everything as promised and as expected',
            text: `The user-friendly mobile app has received extremely positive external feedback. NextEdge Studio’s 
            responsive team delivered a simple yet advanced product. In addition to having strong technical capabilities, 
            they are quick to learn the skills necessary to deliver on the commercial scope of a project.`,
            position: 'Product Manager',
            company: 'Arcs & Glass'
        },

        {
            title: 'I understand why they recieve show much industry recognition',
            text: `The logo has garnered unanimously positive feedback. Committed and easy to work with, 
            NextEdge Studio built a robust understanding of brand identity and translated it into a visual design. 
            Their smooth collaboration and extensive industry knowledge are particularly noteworthy.`,
            position: 'CEO',
            company: 'ApexShares'
        },
        {
            title: 'NextEdge Studio genuinely cares about their customers',
            text: `NextEdge studio was able to take an abstract idea for an app and bring it to reality, 
            while staying true to the original vision. Overall, the app’s design and usability were outstanding.
            The agency distinguished themselves with their personable, responsive communication style.`,
            position: 'Designer',
            company: 'Arifhill Press'
        }
    ]


    const imgCarousel = <div className="lg:px-20 mb-32 lg:mb-60">
        <Slider {...imgSettings} className="carousel">
            {images.map((image, index) => {
                return (
                    <div key={index}>
                        <img src={image.src} alt="carousel" className="w-full" />
                    </div>
                )
            })}
        </Slider>
    </div>


    const reviewCarousel = <div className="lg:px-16">
        <Slider {...reviewSettings} className="">
            {reviews.map((review, index) => (
                <div key={index}>
                    <h6 className="font-semibold">"{review.title}"</h6>
                    <p className="text-lg font-light opacity-70 mt-4 leading-7">
                        {review.text}
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 mt-10">
                        <div>
                            <img src="/img/five_stars.jpg" className="-ml-2 mb-3" alt="Ratings" />
                            <h4 className="font-semibold text-lg ">{review.position}</h4>
                            <h6 className="font-light">{review.company}</h6>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    </div>

    return (
        <>
            {props.review ? reviewCarousel : imgCarousel}
        </>
    )
}

export default Carousel
import React, { useEffect, useContext, useState } from "react"
import Layout from "../components/shared/Layout"
import Button from "../components/form/Button"
import { Link } from "react-router-dom"
import Branding from "../components/services/Branding"
import WebsiteDesign from "../components/services/WebsiteDesign"
import WebDevelopment from "../components/services/WebDevelopment"
import AppDevelopment from "../components/services/AppDevelopment"
import SocailManagement from "../components/services/SocialManagement"
import Seo from "../components/services/Seo"

function ServicesPage() {
    const [currentService, setService] = useState('branding');
    const services = [
        { name: 'Branding', tag: 'branding' },
        { name: 'Website Design', tag: 'websiteDes' },
        { name: 'Website Development & Maintenance', tag: 'websiteDev' },
        { name: 'App Development', tag: 'appDev' },
        { name: 'Social Media Management', tag: 'social' },
        { name: 'SEO', tag: 'seo' },
    ]

    return (
        <Layout title="Services">
            <div className="container-fluid">
                <div className="px-3 md:px-5">
                    <div className="mt-6 lg:mt-12 mb-20 lg:mb-28">
                        <h1 className="text-neBlue text-4xl lg:text-7xl leading-normal lg:leading-relaxed text-left font-light mb-4">
                            We craft digital experiences for outstanding brands.
                        </h1>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-10">
                        <div>
                            <h2 className="mb-4">Services</h2>
                            <p className="text-sm lg:text-lg w-full font-light">
                                Unlock Your Brand's Potential with NextEdgeStudio
                            </p>
                            <p className="text-sm lg:text-lg w-full font-light">
                                As a leading branding agency, NextEdgeStudio's BrandTenders partner with you to develop and nurture a
                                unique brand identity that reflects your company's values and personality.
                                We create captivating brand designs, logos, and more, tailored to your business goals.
                            </p>
                            <p>Our comprehensive design services include:</p>

                            <ul>
                                <li className="font-light">- Custom design</li>
                                <li className="font-light">- SEO optimization</li>
                                <li className="font-light">- UI/UX enhancement</li>
                                <li className="font-light">- Mobile responseiveness</li>
                            </ul>
                            <Link to={"mailto:?to=info@nextedgestudio.com&subject=Project Collaboration&body=Hello, "}>
                                <Button dark css="w-3/4">Schedule a call</Button>
                            </Link>
                        </div>
                        <div>
                            <img src="/img/sample_images/pexels-rdne-7841457.jpg" alt="About us" />
                        </div>
                    </div>

                    <div className="mt-36 lg:mt-44">
                        <div className="grid grid-cols-1 lg:grid-cols-2 mb-16 gap-2">
                            <div>
                                <h2>Our Services</h2>
                                <div className="servicess rounded-2xl w-fit">
                                    {services.map(service => (
                                        <p className={`${service.tag === 'branding' ? 'rounded-t-2xl' : ''} ${service.tag === 'seo' ? 'rounded-b-2xl' : ''}
                                         ${service.tag === currentService ? 'servicess-sel' : ''}`}
                                            key={service.tag}
                                            onClick={() => setService(service.tag)}>
                                            {service.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-10">
                                {currentService === "branding" && <Branding />}
                                {currentService === "websiteDes" && <WebsiteDesign />}
                                {currentService === "websiteDev" && <WebDevelopment />}
                                {currentService === "appDev" && <AppDevelopment />}
                                {currentService === "social" && <SocailManagement />}
                                {currentService === "seo" && <Seo />}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ServicesPage
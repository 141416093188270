import React from "react"
import Button from "./form/Button"
import { Link } from "react-router-dom"

function Services() {
    return (
        <div>
            <div className="lg:flex lg:justify-between mt-32 mb-32 lg:mb-36 lg:mt-48 gap-20 ">
                <div className="flex justify-center -mt-5 lg:w-2/5 mb-10 lg:mt-24">
                    <img className="w-[420px] h-[400px]" src="img/gifs/NextEdge_GIF_Copy_4.gif" alt="Services" />
                </div>

                <div className="lg:w-3/5">
                    <small className="opacity-40">Services</small>
                    <h1 className="mb-5 text-neBlue">Why Choose Us?</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                        <div>
                            <h5>Full Service Experience</h5>
                            <p className="opacity-60 font-light">
                                Why select NextEdge Studio for web design and development services? We offer a powerful
                                combination of web design and development, brand strategy, and digital marketing.
                            </p>
                        </div>

                        <div>
                            <h5>Mobile Responsive Web Design</h5>
                            <p className="opacity-60 font-light">
                                Each of our websites is designed and developed to be mobile responsive
                                first and foremost to provide the best user experience—no matter the type of device.
                            </p>
                        </div>

                        <div>
                            <h5>Top-Level Project Management</h5>
                            <p className="opacity-60 font-light">
                                Our seasoned and reliable project managers guarantee that our clients'
                                website design projects are meticulously organized, effectively managed, and delivered
                                on time with clear expectations.
                            </p>
                        </div>

                        <div>
                            <h5>Client-Centered Process</h5>
                            <p className="opacity-60 font-light">
                                Our dedicated web design team collaborates closely with clients through every stage,
                                including design creation, content development, SEO, development, testing, and launch.
                            </p>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <Link to={'/services'}>
                            <Button light>Our Services</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
import React from "react"
import Button from "../form/Button"
import { Link } from "react-router-dom"

function WebDevelopment({ full }) {
    if (!full) {
        return (
            <div>
                <h1>We envision ideas and bring them to life</h1>
                <p className="my-12 leading-9 font-light">
                    We turn your vision into reality through exceptional website development. From concept to completion,
                    we bring ideas to life with designs that captivate and functionality that drives results.
                </p>
                <Link to={"mailto:?to=info@nextedgestudio.com&subject=Website Development&body=Hello, "}>
                    <Button dark css="w-full">Create your dream website</Button>
                </Link>
            </div>
        )
    }

    return (
        <div></div>
    )
}

export default WebDevelopment
import './App.css';
import '../src/asset/style.css'
import '../src/asset/global.css'
import '../src/asset/bootstrap.min.css'
import { Suspense, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { BrowserRouter, Routes, Route } from "react-router-dom";


// Contexts
import StateContext from "./context/StateContext"
import DispatchContext from "./context/DispatchContext"


// components
import LoadingIcon from './components/LoadingIcon';
import PageNotFound from './components/PageNotFound';


// pages
import HomePage from './pages/HomePage';
import About from './pages/About';
import Header from './components/shared/Header';
import Footer from './components/Footer';
import ServicesPage from './pages/ServicesPage';
import ContactUsPage from './pages/ContactUsPage';
import OurProcess from './pages/OurProcess';
import TermsAndConditions from './pages/Terms&Conditions';


function App() {
  const initialState = {
    title: 'Home',
    sideBar: false
  }

  function mainReducer(draft, action) {
    switch (action.type) {
      case "title":
        draft.title = action.value
        break;

      case "toggleSideBar":
        draft.sideBar = !draft.sideBar
        break;

      case "closeSideBar":
        draft.sideBar = false
        break;
      default:
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(mainReducer, initialState)
  // console.log(state)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Suspense fallback={<LoadingIcon />}>
            <Header />
            <Routes>
              <Route path="/" element={<HomePage />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/services" element={<ServicesPage />}></Route>
              <Route path="/contact" element={<ContactUsPage />}></Route>
              <Route path="/our-process" element={<OurProcess />}></Route>
              <Route path="/terms&conditions" element={<TermsAndConditions />}></Route>

              <Route path="*" element={<PageNotFound />}></Route>
            </Routes>
            <Footer />
          </Suspense>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>

  );
}

export default App;

import React from "react"
import Carousel from "./Carousel"

function ClientsReview() {
    return (
        <div className="my-40">
            <div className="text-right mb-20">
                <small className="opacity-40">Feedbacks</small>
                <h1 className="mb-5 text-neBlue">We work with amazing clients</h1>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                <div>
                    <div className="flex mx-auto sm:w-full  mb-5">
                        <img className="" src="img/sample_images/pexels-karolina-grabowska-7875999.jpg" alt="Reviews" />
                    </div>
                </div>
                <div>
                    <Carousel review/>
                </div>
            </div>
        </div>
    )
}

export default ClientsReview
import React from "react"
import Button from "../form/Button"
import { Link } from "react-router-dom"

function Branding({ full }) {
  if (!full) {
    return (
      <div>
        <h1>Your Stategic Brand Builds The Right Tone</h1>
        <p className="my-12 leading-9 font-light">
          Unlock your brand’s potential with our expert Brand Strategy services. We immerse ourselves in your brand ethos,
          creating a tailored roadmap that aligns with your vision and captivates your audience.
        </p>
        <Link to={"mailto:?to=info@nextedgestudio.com&subject=Project Branding&body=Hello, "}>
          <Button dark css="w-full">Let's Talk Brand Strategy</Button>
        </Link>
      </div>
    )
  }

  return (
    <div></div>
  )
}

export default Branding
import React, { useEffect, useContext } from "react"
import Container from "../Container"
import DispatchContext from "../../context/DispatchContext";
import SideBar from "./SideBar";
import StateContext from "../../context/StateContext";


function Layout(props) {
    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext)
    const { sideBar } = appState

    useEffect(() => {
        document.title = `${props.title} | NextEdge Studio`
        window.scrollTo(0, 0)
        appDispatch({ type: "title", value: props.title })
        appDispatch({ type: "closeSideBar" })
    }, [props.title])


    return (
        <div className="relative">
            <Container css={props.css}>
                <div className={`h-full fixed w-1/2 left-0 top-0 bottom-0 overflow-y ${sideBar ? "z-50" : "hidden"} `}>
                    <SideBar />
                </div>
                <div className="">
                    {props.children}
                </div>
            </Container>
        </div>
    )
}

export default Layout
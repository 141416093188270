import React from "react"
import Button from "../form/Button"
import { Link } from "react-router-dom"

function WebsiteDesign({ full }) {
    if (!full) {
        return (
            <div>
                <h1>Design infused with boundless creativity</h1>
                <p className="my-12 leading-9 font-light">
                    Elevate your online presence with our brand-focused web design services. Our designs 
                    combine visual appeal with user-friendly functionality, delivering seamless experiences 
                    that engage and convert visitors into loyal customers.
                </p>
                <Link to={"mailto:?to=info@nextedgestudio.com&subject=Website Design&body=Hello, "}>
                    <Button dark css="w-full">Let's Talk Website Design</Button>
                </Link>
            </div>
        )
    }

    return (
        <div></div>
    )
}

export default WebsiteDesign
import React from "react"
import Button from "./form/Button"
import { Link } from "react-router-dom"

function Footer() {
    return (
        <div className="bg-neBlue p-10 mt-44">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 pb-10">
                <div>
                    <h1 className="text-[70px] lg:text-[100px] text-neWhite">Let's work together.</h1>
                </div>
                <div>
                    <form className="footer-form lg:pl-10">
                        <input type="text" placeholder="Name" required className="" />
                        <input type="text" placeholder="Email" required className="" />
                        <input type="text" placeholder="Phone Number" required className="" />
                        <input type="text" placeholder="Company" required className="" />
                        <textarea type="text" placeholder="Message" required className="" />
                        <div className="flex justify-center">
                            <Button dark css='w-1/2'>Submit</Button>
                        </div>
                    </form>
                </div>
                <div className="footer-l">
                    <h6>Lagos, Nigeria</h6>
                    <a href="mailto:info@nextedgestudio.com">
                        <h6>info@nextedgestudio.com</h6>
                    </a>

                    <h6 className="mt-10">&copy; 2025 NEXTEDGE STUDIO. All Rights Reserved</h6>
                    <Link to={'/terms&conditions'} target="_blank" className=""><h6>Terms & Conditions</h6></Link>
                </div>
                <div className="grid grid-cols-2 ">
                    <Link to={'https://www.facebook.com/profile.php?id=61570410362321'} target="_blank"
                    className="text-neGrey">
                        Facebook
                    </Link>
                    <Link to={'https://x.com/NextEdgeStudio?t=BcTnrF9ixcpCqbaR0FbRLw&s=09'} target="_blank"
                    className="text-neGrey">
                        X
                    </Link>
                    <Link to={'https://www.instagram.com/nextedgestudio/profilecard/?igsh=MTlqb3dydnZ5NzR3eA=='} target="_blank"
                     className="text-neGrey">
                        Instagram
                    </Link>
                    <Link to={"https://wa.me/+2348088642060"} target="_blank" className="text-neGrey">
                        WhatsApp
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer
import React, { useEffect, useContext } from "react"
import Layout from "../components/shared/Layout"
import Button from "../components/form/Button"
import { Link } from "react-router-dom"

function About() {
    return (
        <Layout title="About">
            <div className="container-fluid">
                <div className="px-3 md:px-5">
                    <div className="mt-6 lg:mt-12 mb-20 lg:mb-28">
                        <h1 className="text-neBlue text-4xl lg:text-7xl leading-normal lg:leading-relaxed text-left font-light mb-4">
                            We Create Digital Experiences
                            That Drive Tangible Business Success
                        </h1>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-10">
                        <div>
                            <h2 className="mb-4">About Us</h2>
                            <p className="lg:text-lg w-full font-light">
                                Our mission is to empower high-growth companies to unlock the full
                                potential of their most valuable digital assets.
                            </p>
                            <p className="lg:text-lg w-full font-light">
                                NextEdgeStudio crafts a powerful blend of digital marketing strategy, innovative website design, and tailored technology solutions.
                            </p>
                            <p className="lg:text-lg w-full font-light">
                                We are dedicated to creating and implementing effective brand and marketing strategies.
                                We design and develop world-class websites and digital marketing campaigns that accurately represent
                                our clients' existing brand image.
                            </p>
                            <p className="lg:text-lg w-full font-light">
                                For newly established companies, our team develops and launches unique brand identities, voices, and
                                digital marketing strategies tailored to drive their success.
                            </p>
                            <Link to={"mailto:?to=info@nextedgestudio.com&subject=Project Collaboration&body=Hello, "}>
                                <Button dark css="w-3/4">Talk to an expert</Button>
                            </Link>
                        </div>
                        <div>
                            <img src="/img/sample_images/pexels-august-de-richelieu-4427905.jpg" alt="About us" />
                        </div>
                    </div>

                    <div className="mt-36 lg:mt-44">
                        <div className="grid grid-cols-1 lg:grid-cols-2 mb-16 gap-2">
                            <div>
                                <h2>Why NextEdge Studio?</h2>
                            </div>
                            <h6 className="flex lg:w-1/2 sm:justify-self-end justify-self-center font-light">
                                Some of the world's leading companies have partnered with NextEdge Studio.
                            </h6>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            <div className="about_card">
                                <img src="/img/gifs/NextEdge_GIF_C_py_3.gif" alt="gif" />
                                <h4>
                                    The Key Differentiator
                                </h4>
                                <p className="font-light">
                                    End-To-End Digital Marketing Division Focused On Driving More Users, Leads And Sales
                                </p>
                            </div>
                            <div className="about_card">
                                <img src="/img/gifs/NextEdge_GIF_Copy_2.gif" alt="gif" />
                                <h4>
                                    Why Not Other Agencies
                                </h4>
                                <p className="font-light">
                                    Strategic Vs. Tactical: They Rely On Just Design Or Development Skills
                                </p>
                            </div>
                            <div className="about_card">
                                <img src="/img/gifs/NextEdge_GIF_Copy_5.gif" alt="gif" />
                                <h4>
                                    What We Provide
                                </h4>
                                <p className="font-light">
                                    Great Storytelling. Amazing Design. Invisible Technology. Higher Conversion Rates
                                </p>
                            </div>
                            <div className="about_card">
                                <img src="/img/gifs/NextEdge_GIF_C_py_7.gif" alt="gif" />
                                <h4>
                                    Our Process
                                </h4>
                                <p className="font-light">
                                    Faster Project Timelines, A Smoother Delivery Process, And Increased Business KPI’s.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About
import React from "react"
import Layout from "../components/shared/Layout"

function OurProcess() {
    const numClass = "text-center text-8xl my-auto text-neGrey hidden md:block"

    return (
        <Layout title="Our Process">
            <div className="md:mt-10 mx-4 lg:mx-10">
                <div className="mt-6 lg:mt-12 mb-28 lg:mb-36">
                    <h1 className="text-neBlue text-4xl lg:text-7xl leading-normal lg:leading-relaxed text-left font-light mb-4">
                        A holistic approach to digital integration.
                    </h1>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-40">
                    {/* 1 */}
                    <h1 className={`${numClass}`}>1</h1>
                    <div className="">
                        <h5 className="text-nePurple uppercase"><span className="md:hidden">1.</span> Messaging & Storytelling</h5>
                        <h3 className="my-4 text-neBlue">Captivate visitors with content tailored to specific personas</h3>
                        <p className="font-light">
                            By leveraging website analytics, persona research, and message testing,
                            we boost product demand and enhance engagement.
                        </p>
                    </div>

                    {/* 2 */}
                    <div className="">
                        <h5 className="text-nePurple uppercase"><span className="md:hidden">2.</span> Frameworks and Scalable Methodologies</h5>
                        <h3 className="my-4 text-neBlue">A strategy that aligns and enhances both sales and marketing efforts</h3>
                        <p className="font-light">
                            We evaluate competitive research alongside your sales, marketing, and brand strategies to craft a successful website implementation.
                        </p>
                    </div>
                    <h1 className={`${numClass}`}>2</h1>

                    {/* 3 */}
                    <h1 className={`${numClass}`}>3</h1>
                    <div className="">
                        <h5 className="text-nePurple uppercase"><span className="md:hidden">3.</span> UX AND VISUAL DESIGN</h5>
                        <h3 className="my-4 text-neBlue">Creating a memorable design that prioritizes the user experience</h3>
                        <p className="font-light">
                            Persona research shapes user experiences that guide visitors through a visual journey, highlighting what
                            makes your brand and solution unique.
                        </p>
                    </div>

                    {/* 4 */}
                    <div className="">
                        <h5 className="text-nePurple uppercase"><span className="md:hidden">4.</span> TOOLS & TECHNOLOGY</h5>
                        <h3 className="my-4 text-neBlue">User-friendly and powered by robust technology</h3>
                        <p className="font-light">
                            With expertise in all major platform technologies, we ensure your website is optimized for 
                            performance, lead conversion, regular updates, and A/B testing for continuous enhancement.
                        </p>
                    </div>
                    <h1 className={`${numClass}`}>4</h1>
                </div>
            </div>
        </Layout>
    )
}

export default OurProcess
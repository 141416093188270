import React from "react"
import Button from "../form/Button"
import { Link } from "react-router-dom"

function AppDevelopment({ full }) {
    if (!full) {
        return (
            <div>
                <h1>We create tailored app development solutions</h1>
                <p className="my-12 leading-9 font-light">
                    Our custom app development solutions are designed to captivate and inspire. By blending innovative design with 
                    seamless functionality, we deliver apps that not only meet your unique needs but also provide an engaging and 
                    delightful user experience. Let us help bring your vision to life with precision and creativity.
                </p>
                <Link to={"mailto:?to=info@nextedgestudio.com&subject=App Development&body=Hello, "}>
                    <Button dark css="w-full">Let's Talk App Development</Button>
                </Link>
            </div>
        )
    }

    return (
        <div></div>
    )
}

export default AppDevelopment
import React, { useEffect, useContext } from "react"
import Layout from "../components/shared/Layout"
import { Link } from "react-router-dom"

function ContactUsPage() {
    return (
        <Layout title="Contact">
            <div className="container-fluid md:mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="p-2 md:p-10 pt-5 md:pt-0">
                        <h3 className="mb-4">Let's Talk</h3>
                        <p className="text-sm lg:text-lg w-full font-light">
                            At NextEdge Studio, we're shaping the future of Enterprise Digital Marketing.
                            Schedule a 30-minute call so we can learn more about your project.
                        </p>
                        <p className="text-sm lg:text-lg w-full font-light">
                            Whether you need innovative website design, mobile-responsive app development, digital marketing and SEO services,
                            or a cutting-edge 3D product configurator, NextEdge Studio is here to create
                            a powerful online presence for you.
                        </p>
                        <p className="text-sm lg:text-lg w-full font-light">
                            With over 28 years of experience in marketing and advertising, combined with
                            award-winning creative design and an industry-leading development team, we deliver the best the industry has to offer.
                            Reach out to us, and let’s create something extraordinary together!
                        </p>
                    </div>
                    <div className="p-10 md:py-28">
                        <img src="/img/sample_images/pexels-mart-production-7709296.jpg" alt="About us" />
                    </div>
                </div>
                <div className="grid grid-cols-4 mt-20 contact-icons px-5 sm:px-20 lg:px-80 place-items-center">
                    <Link to={"https://wa.me/+2348088642060"}  target="_blank"><img src="/icons/whatsapp_icon.png" alt="whatsapp" /></Link>
                    <Link to={'https://www.instagram.com/nextedgestudio/profilecard/?igsh=MTlqb3dydnZ5NzR3eA=='} target="_blank">
                        <img src="/icons/instagram_icon.png" alt="instagram" />
                    </Link>
                    <Link target="_blank"><img src="/icons/telegram_icon.png" alt="telegram" /></Link>
                    <Link to={"mailto:?to=info@nextedgestudio.com&subject=Project Collaboration&body=Hello, "} target="_blank">
                        <img src="/icons/email_icon.png" alt="email" />
                    </Link>
                </div>
            </div>
        </Layout>
    )
}

export default ContactUsPage
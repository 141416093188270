import React from "react"
import Button from "../form/Button"
import { Link } from "react-router-dom"

function Seo({ full }) {
    if (!full) {
        return (
            <div>
                <h1>Unlock visibility with Search Engine Optimization (SEO)</h1>
                <p className="my-12 leading-9 font-light">
                    Enhance your online presence and attract more visitors with our expert Search Engine Optimization (SEO) services. 
                    We optimize your website to rank higher on search engines, ensuring your business stands out and reaches the right audience. 
                    Unlock the power of visibility and drive meaningful growth with our proven SEO strategies.
                </p>
                <Link to={"mailto:?to=info@nextedgestudio.com&subject=SEO&body=Hello, "}>
                    <Button dark css="w-full">Boost your visibility </Button>
                </Link>
            </div>
        )
    }

    return (
        <div></div>
    )
}

export default Seo
import React from "react";
import Layout from "./shared/Layout"
import { Link } from "react-router-dom"
import Header from "./shared/Header";

function PageNotFound() {
  return (
    <Layout title="404">
      <div className="text-center page-not-found">
        <i className="fas fa-blind page-not-found-icon"></i>
        <h2>Whoops, can't find that page</h2>
        <p className="lead text-muted">Go to <Link className="link pnf-hoome" to='/'>homepage</Link></p>
      </div>
    </Layout>
  )
}

export default PageNotFound
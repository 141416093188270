import React from "react"
import Button from "./form/Button"

function ContactBreak() {
    return (
        <div className="my-58">
            <div>
                <div className="container">
                    <div className="section-header text-center">
                        <small className="opacity-40">Partner with us</small>
                        <h4 className="text-3xl lg:text-5xl text-neBlue mb-3">Interested in <span>collabrating</span> with us?</h4>
                    </div>
                    <div
                        className="container banner-text text-center"
                    >
                        <p className="font-light text-sm lg:text-[16px]">
                            We are always open to discussing product design work or partnership
                            opportunities.
                        </p>
                        <a
                            className="btn"
                            href="mailto:?to=info@nextedgestudio.com&subject=Project Collaboration&body=Hello, "
                        ><Button light>Start Conversation</Button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactBreak
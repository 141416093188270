import React, { useEffect, useContext } from "react"
import Layout from "../components/shared/Layout"

function TermsAndConditions() {
    return (
        <Layout title="Terms & Conditions">
            <div className="container-fluid terms">
                <div className="px-3 md:px-5">
                    <h1 className="mt-16">Terms and Conditions</h1>
                    <p className="my-4">
                        These Terms and Conditions constitute a legally binding agreement between you and NextEdgeStudio, concerning your access to and use of our website.
                    </p>

                    <h5>Agreement to Terms</h5>
                    <p>
                        By accessing our website, you agree to be bound by these Terms and Conditions. If you do not agree, you must discontinue use immediately.
                    </p>
                    <h5>Changes to Terms</h5>
                    <p>
                        We reserve the right to modify these Terms and Conditions at any time. Your continued use of our website following changes to these Terms and Conditions will be deemed acceptance of those changes.
                    </p>
                    <h5>User Representations</h5>
                    <p>
                        By using our website, you represent and warrant that you are at least 18 years old, and that you have the right to agree to these Terms and Conditions.
                    </p>
                    <h5>Prohibited Activities</h5>
                    <p>
                        You may not use our website for any unlawful or unauthorized purpose. Prohibited activities include, but are not limited to:
                    </p>
                    <ul>
                        <li>
                            - Using the website for commercial purposes without our permission
                        </li>
                        <li>
                            - Posting or transmitting unauthorized or unsolicited content
                        </li>
                        <li>
                            - Interfering with the website's operation or security
                        </li>
                        <li>
                            - Violating any applicable laws or regulations
                        </li>
                    </ul>

                    <h5>User-Generated Content</h5>
                    <p>
                        You grant us a non-exclusive license to use, reproduce, modify, and distribute any content you post or submit to our website.
                    </p>
                    <h5>
                        Submissions
                    </h5>
                    <p>
                        Any ideas, suggestions, or feedback you provide to us will be deemed non-confidential and non-proprietary.
                    </p>
                    <h5>Third-Party Websites and Content</h5>
                    <p>
                        Our website may contain links to third-party websites and content. We are not responsible for the content or policies of these third-party websites.
                    </p>

                    <h5>Site Management</h5>
                    <p>
                        We reserve the right to monitor our website for violations of these Terms and Conditions, and to take appropriate action in response to any violation.
                    </p>
                    <h5>Privacy Policy</h5>
                    <p>
                        Our Privacy Policy is incorporated into these Terms and Conditions. By using our website, you agree to be bound by our Privacy Policy.
                    </p>
                    <h5>Digital Millennium Copyright Act (DMCA) Notice and Policy</h5>
                    <p>
                        We respect the intellectual property rights of others. If you believe that any material available on our website infringes upon your copyright, please notify our Designated Copyright Agent.
                    </p>
                    <h5>Contact Us</h5>
                    <p>
                        If you have any questions or concerns about these Terms and Conditions, please contact us at [contact@nextedgestudio.com].
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default TermsAndConditions